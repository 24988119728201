/**
 * IsMobile:
 * Clase que agrega corte de palabrabra dependiendo del dispositivo.
 *
 *
 * Ejemplo de uso:
 * <span class="underline js-clamp" data-lines="1" data-mobile-lines="2">
 *      texto muy largo que queremos que se corte en caso de superar x cantidad de lineas
 * </span>
 *
 * <script>
 *  var cw = new ClampWrapper;
 *  cw.init();
 * </script>
 *
 * @author mreboredo
 */

(function(window, document, undefined) {

    'use strict';

    function ClampWrapper(usrOptions){
        var defaultOptions = {
            class: '.js-clamp',
            clampSettings: {
            }
        };

        function addStyles(elmnt){
            elmnt.style.display = 'block';
            elmnt.style.width = '100%';
        }

        function removeStyles(elmnt){
            elmnt.removeAttribute("style");
        }

        function getLines(elmnt){
            var lines;
            switch (Educar.DeviceInfo.size) {
                case 'XS':
                    lines = elmnt.getAttribute('data-mobile-lines');
                    if(!lines){
                        lines = elmnt.getAttribute('data-lines');
                    }
                    break;
                default:
                    lines = elmnt.getAttribute('data-lines');
            }
            return lines;
        }

        /**
         * obtiene todos los elementos a hacer corte de palabra. Por cada uno de los elementos
         * se se crea una instancia de clamp.
         *
         * @returns {Array} Se retorna un array con con la instancia de clamp y el elemento al cual se
         * hizo corte de palabra
         */
        function init(){
            var opt = Educar.Helpers.extend(true,defaultOptions, usrOptions);
            var elmnts = document.querySelectorAll(opt.class);
            var arrReturn = [];

            for (var i = 0; i < elmnts.length; i++) {

                var elmnt = elmnts[i];

                addStyles(elmnt);

                opt.clampSettings.lines = getLines(elmnt);
                var clampInstance = new Educar.ClampAction(elmnt, opt.clampSettings);

                arrReturn[i] = {
                    elmnt: elmnt,
                    clampInstance: clampInstance
                };

                removeStyles(elmnt);
            }
            return arrReturn;
        }
        return init();
    }
    if(window.Educar == undefined){
        window.Educar = {};
    }
    window.Educar.Clamp = ClampWrapper;
})(window, document);
