/**
 * Class used to adjust description overlay height to parent.
 * IMPORTANT: Run after clamp!
 *
 * Example:
 * <div class="overlay js-adjust">
 *   <span class="js-clamp" data-lines="4">....</span>
 * </div>
 *
 * <script>
 *  var adh = new AdjustDescriptionHeight;
 *  adh.init();
 * </script>
 *
 * @author sruizdiaz
 */

(function(window, document, undefined) {

    'use strict';

    function AdjustDescriptionHeight(usrOptions){
        var defaultOptions = {
            class: '.js-descriptionHeight',

        };

        function init(){
            var opt = Educar.Helpers.extend(true,defaultOptions, usrOptions);
            var elmnts = document.querySelectorAll(opt.class);
            var arrReturn = [];

            for (var i = 0; i < elmnts.length; i++) {
                var clampHeight = window.getComputedStyle(elmnts[i], null).getPropertyValue("height");
                var overlay = elmnts[i].parentElement;

                overlay.style.maxHeight = clampHeight;

                console.log(clampHeight);
            }
            return arrReturn;
        }

        return init();
    }
    if(window.Educar == undefined){
        window.Educar = {};
    }
    window.Educar.AdjustDescriptionHeight = AdjustDescriptionHeight;
})(window, document);

