/**
 * Helpers
 *
 * @author mreboredo
 */

(function(window, document, $, undefined) {

    'use strict';

    function Helpers(){

        /**
         * Metodo que agrega funcion anonima al evento
         * window.onload sin pisar el codigo existente y
         * nuevo que se agrega
         *
         * @param Function
         *
         * @return void
         *
         */
        this.onLoad = function(func){
            var oldonload = window.onload;
            if (typeof window.onload != 'function') {
                window.onload = func;
            } else {
                window.onload = function() {
                    if (oldonload) {
                        oldonload();
                    }
                    func();
                }
            }
        };

        /**
         * Chequea si la variable es array
         *
         * @param variable
         *
         * @return bool
         *
         */
        this.isArray = function(variable){
            var result = false;
            if(Object.prototype.toString.call(variable) === '[object Array]') {
                result = true;
            }
            return result;
        };

        /**
         * Chequea si la variable es node list
         *
         * @param variable
         *
         * @return bool
         *
         */
        this.isNodeList = function(variable){
            var result = false;
            if(Object.prototype.toString.call(variable) === '[object NodeList]') {
                result = true;
            }
            return result;
        };

        /**
         * Obtiene variables en url
         *
         * @return obj
         *
         */
        this.getUrlParams = function(){
            var match,
                pl     = /\+/g,
                search = /([^&=]+)=?([^&]*)/g,
                decode = function (s) {
                    return decodeURIComponent(s.replace(pl, " "));
                },
                query  = window.location.search.substring(1);

            var urlParams = {};
            while (match = search.exec(query)){
                urlParams[decode(match[1])] = decode(match[2]);
            }
            return urlParams
        };

        /**
         * genera string en formato variables de url
         *
         * @return str
         *
         */
        this.objectToUrlParams = function(obj){
            var result = '?';
            for (var key in obj) {
                if (result !== '?') {
                    result += "&";
                }
                result += key + "=" + encodeURIComponent(obj[key]);
            }
            return result;
        };

        /**
         * mergea 2 objetos
         *
         * @param default obj
         * @param options obj
         *
         * @return obj
         *
         */
        this.extend = function(){

            var extended = {},
                deep = false,
                i = 0,
                length = arguments.length,
                that = this;

            //chequea la profundidad del merge
            if (Object.prototype.toString.call(arguments[0]) === '[object Boolean]') {
                deep = arguments[0];
                i++;
            }

            // mergea el objeto con el que se extiende
            var merge = function (obj) {
                for (var prop in obj) {
                    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                        //si hay mergeo progundo y la propiedad es un objecto se mergean las propiedades
                        if (deep && Object.prototype.toString.call(obj[prop]) === '[object Object]') {
                            extended[prop] = that.extend(true, extended[prop], obj[prop]);
                        } else {
                            extended[prop] = obj[prop];
                        }
                    }
                }
            };

            // recorre cada objeto y lo mergea
            for (; i < length; i++) {
                var obj = arguments[i];
                merge(obj);
            }

            return extended;
        };

        function getSelectorType(selector){
            var type = selector.charAt(0);
            switch (type) {
                case '#':
                    return 'id';
                    break;
                case '.':
                    return  'class';
                    break;
                case '[':
                    return  'attribute';
                    break;
                default:
                    return 'tag';
                    break;
            }
        };

        /**
         * Recorre los nodos padres del elemento y los devueve como array
         * pueden ser filtrados por un selector
         *
         * @param elmnt {dom elemnt}
         * @param selector {string}
         *
         * @return {array} || {null}
         *
         */
        this.getParents =  function (elmnt, selector) {

            var parents = [];

            if(selector){
                var type = getSelectorType(selector);
            }

            for(; elmnt && elmnt !== document; elmnt = elmnt.parentNode) {
                if(selector){

                    if(type === 'class'){
                        if(elmnt.classList.contains(selector.substr(1))){
                            parents.push(elmnt);
                        }
                    }

                    if(type === 'id') {
                        if(elmnt.id === selector.substr(1)){
                            parents.push(elmnt);
                        }
                    }

                    if(type === 'attribute') {
                        if (elmnt.hasAttribute(selector.substr(1, selector.length - 1))){
                            parents.push(elmnt);
                        }
                    }

                    if(type === 'tag'){
                        if(elmnt.tagName.toLowerCase() === selector){
                            parents.push(elmnt);
                        }

                    }

                }else{
                    parents.push(elmnt);
                }

            }

            // Return parents if any exist
            if(parents.length === 0) {
                return null;
            }else{
                return parents;
            }

        };

        /**
         * Recorre los nodos padres del elemento y los devueve como array
         * pueden ser filtrados por un selector
         *
         * @param elmnt {dom elemnt}
         * @param selector {string}
         *
         * @return {array} || {null}
         *
         */
        this.secondsToTime = function(secs){
            var hours = Math.floor(secs / (60 * 60)),
                divisorMinutes = secs % (60 * 60),
                minutes = Math.floor(divisorMinutes / 60),
                divisorSeconds = divisorMinutes % 60,
                seconds = Math.ceil(divisorSeconds);

                if(hours < 10){
                    hours = '0'+ hours;
                }

                if(minutes < 10){
                    minutes = '0'+ minutes;
                }

                if(seconds < 10){
                    seconds = '0'+ seconds;
                }

                var time = {
                    h: hours,
                    m: minutes,
                    s: seconds
                };
            return time;
        }

        this.get = function(uri){
            return {
                then: function(resolve, reject){
                    var httpRequest = new XMLHttpRequest();
                    httpRequest.onreadystatechange = function (event) {
                        if (httpRequest.readyState === XMLHttpRequest.DONE) {
                            if (httpRequest.status === 200) {
                                resolve(httpRequest.responseText);
                            }else{
                                reject(httpRequest.responseText);
                            }
                        }
                    };
                    httpRequest.open('GET', uri, true);
                    httpRequest.send(null);
                }
            }
        }

    }

    if(window.Educar == undefined){
        window.Educar = {};
    }
    window.Educar.Helpers = new Helpers();

})(window, document, $);
