/**
 * AudioPlayer:
 * clase para controlar player de audio nativo HTML5
 *
 *
 *
 * Ejemplo de uso:
 *
 *  var AudioPlayer = new Educar.AudioPlayer();
 *  AudioPlayer.init({
 *      source:         'audioplayer-source',
 *      play:           'btn-play',
 *      progressBar:    'progress-bar',
 *      time:           'audioplayer-time'
 *  });
 *
 * @author sruiz/mreboredo
 */
//@TODO: repensar un poco junto con playlist. no quedo muy copado esto
(function(window, document, $, undefined) {
    'use strict';

    function AudioPlayer(usrOptions){
        var options = { //opciones de configuracion
            player:             '#js-player',
            play:               '.js-play',
            next:               '.js-next',
            prev:               '.js-prev',
            progressPlayback:   '.js-progressPlayback',
            progressBuffer:     '.js-progressBuffer',
            progressContainer:  '.js-progressContainer',
            time:               '.js-time',
            nextCallback:       (usrOptions.nextCallback ? usrOptions.nextCallback :function(){}),
            prevCallback:       (usrOptions.prevCallback ? usrOptions.prevCallback :function(){})
        },
        that = this,
        player = null,
        play = null,
        progressBuffer = null,
        progressPlayback = null,
        time = null;


        function playClickHandler(){
            if(player.paused){
                that.startPlay();
            }else{
                play.firstChild.classList.remove('educar-icon-pause');
                play.firstChild.classList.add('educar-icon-play');
                player.pause();
            }
        }

        function nextClickHandler(){
            options.nextCallback();
        }

        function prevClickHandler(){
            options.prevCallback();
        }

        function setTimer(currentTime, duration){
            var cTime = Educar.Helpers.secondsToTime(currentTime),
                dur = Educar.Helpers.secondsToTime(duration),
                durationString = '',
                timeString = '';

            if(dur.h != 0){
                durationString += dur.h + ':';
                timeString += currentTime.h + ':';
            }
            durationString +=  dur.m + ':' + dur.s;
            timeString +=  cTime.m + ':' + cTime.s;
            time.textContent = timeString + ' / ' + durationString;
        }

        function setProgress(elmnt, current, total){
            var progressPercentage = (current * 100) / total;
            elmnt.style.width = progressPercentage + '%';
        }

        function playbackHandler(evt){
            setTimer(this.currentTime, this.duration);
            setProgress(progressPlayback, this.currentTime, this.duration);
        }

        function bufferHandler(evt){
            // setProgress(progressBuffer, evt.loaded, evt.total);
        }

        function seekHandler(evt){
            var containerMargin = parseInt(window.getComputedStyle(document.querySelector('.resourcesDetail'), null).marginLeft.replace('px', '')),
                x = (evt.pageX - this.offsetLeft) - containerMargin,
                elmntWidth = parseFloat(window.getComputedStyle(this, null).width.replace('px', '')),
                seekedTime = parseFloat((x * player.duration) / elmntWidth);
                player.currentTime = seekedTime;
                that.startPlay();

        }

        function completeHandler(){
            options.nextCallback();
        }

        function addEventListeners(){
            play = document.querySelector(options.play);
            play.addEventListener('click', playClickHandler);

            var next = document.querySelector(options.next);
            next.addEventListener('click', nextClickHandler);

            var prev = document.querySelector(options.prev);
            prev.addEventListener('click', prevClickHandler);

            player.addEventListener('timeupdate', playbackHandler);
            // player.addEventListener('progress', bufferHandler);
            player.addEventListener('ended', completeHandler);

            var progressCnt = document.querySelector(options.progressContainer).addEventListener('click', seekHandler);

        }

        this.startPlay = function(){
            play.firstChild.classList.remove('educar-icon-play');
            play.firstChild.classList.add('educar-icon-pause');
            player.play();
        }

        function init(usrOptions) {
            $.extend(options, usrOptions);

            player = document.querySelector(options.player);
            progressPlayback = document.querySelector(options.progressPlayback);
            progressBuffer = document.querySelector(options.progressBuffer);
            time = document.querySelector(options.time);

            addEventListeners();
        }
        init();
    }

    if(window.Educar == undefined){
        window.Educar = {};
    }

    window.Educar.AudioPlayer = AudioPlayer;

})(window, document, $);


/*****************/
/*var btnPlay = document.getElementsByClassName('btn-play');
var btnPrev = document.getElementsByClassName('btn-prev');
var btnNext = document.getElementsByClassName('btn-next');

function playPause() {
    document.getElementsByClassName('source').play();
    document.getElementById('source').pause();
}*/

