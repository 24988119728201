/**
 * IframeHeight:
 * Obtiene el heigth del contenido del iframe y lo setea en el mismo
 * para que no se vea la scroll bar
 *
 *
 * @author mreboredo
 */

(function(window, document, undefined) {

    'use strict';

    function IframeHeight(options){
        /**
         * chequea el tipo de vento y ejecuta el metodo correspondiente
         *
         * @return void
         */
        function messageHandler(event){
            var data = event.data;
            if(data.event === 'iframeSize'){
                options.iframe.style.height = data.height + 'px';
            }
        }

        /**
         * Constructor
         *
         * @return void
         */
        function init(){
            window.addEventListener('message', messageHandler);
            if(options.onInit){
                options.onInit(options.iframe);
            }
        }
        init();
    }

    if(window.Educar == undefined){
        window.Educar = {};
    }
    window.Educar.IframeHeight = IframeHeight;

})(window, document);
