/**
 * AnaliticsEvents:
 * clase para simplificar y ordenar los eventos que hay que disparar para trakear analitics.
 *
 * Para inicializar:
 * var ae = new Educar.AnaliticsEvents();
 *
 * Ejemplo de trackeo:
 * <a class="js-trackingAction"
 *      data-eventCategory="eventCategory"
 *      data-eventAction="eventAction"
 *      data-eventLabel="eventLabel"
 *      data-eventValue="eventValue"
 *      data-fieldObject="fieldObject"
 * >Test</a></h1>
 *
 * @author mreboredo
 */

(function (window, document, undefined) {

    'use strict';

    function AnaliticsEvents() {

        var CONSTANTS = {
            btnsClass: '.js-trackingAction'
        }

        function elmntClickHandler(e) {
            var element = e.currentTarget.outerHTML;
            // alert(
            //     'Valor atributo categoria: \n ' + this.getAttribute('data-eventCategory') + '\n\n' +
            //     'Valor atributo accion: \n ' + this.getAttribute('data-eventAction') + '\n\n' +
            //     'Valor atributo label: \n ' + this.getAttribute('data-eventLabel') + '\n'
            // );
            ga('send', 'event', this.getAttribute('data-eventCategory'), this.getAttribute('data-eventAction'), this.getAttribute('data-eventLabel'));
        }

        /**
         * Constructor
         *
         * @return void
         *
         */
        function init() {
            var arrElmnts = document.querySelectorAll(CONSTANTS.btnsClass);

            for (var i = 0, length1 = arrElmnts.length; i < length1; i++) {
                arrElmnts[i].addEventListener('click', elmntClickHandler);
            }
        }
        init();
    }

    if (window.Educar == undefined) {
        window.Educar = {};
    }
    window.Educar.AnaliticsEvents = AnaliticsEvents;

})(window, document);
