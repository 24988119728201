/**
 * CascadeDropdown:
 *
 * Ejemplo de uso:
 *
 * @author mreboredo
 */

(function(window, document, undefined) {

    'use strict';

    function CascadeDropdown(options){

        var that = this,
            dropdown = null,//se podria sacar esta var?
            parentDropdown = null;//se podria sacar esta var?

        this.show = function(parentIndex, elmnt){
            options.showCallback(parentIndex, elmnt);//le paso el this(elmnt) o dropdown?
            dropdown.disabled = false;
            dropdown.parentNode.classList.remove('hidden');//deberia ser configurable cual queres esconder?
        }

        this.hide = function(parentIndex, elmnt){
            options.hideCallback(parentIndex, elmnt);//le paso el this(elmnt) o dropdown?
            dropdown.disabled = true;
            dropdown.parentNode.classList.add('hidden');
        }

        this.changeDropdownOptions = function(ddOptions){

            if(options.defaultOption){
                dropdown.innerHTML = '<option selected="selected" value="' + options.defaultOption.value+ '">'+options.defaultOption.text+'</option>';
            }else{
                dropdown.innerHTML = "";
            }
            for ( var id in ddOptions ) {
                dropdown.innerHTML += '<option value="' + id + '">' + ddOptions[id] + '</option>';
            }
        }

        function checkVisibility(index, arr){
            for (var i = arr.length - 1; i >= 0; i--) {//uso for porque el foreach no se puede cortar
                if(arr[i] == index){
                    return index;
                }
            }
            return false;
        }

        function parentChangeHandler(event){
            var hasFound = false,
                match = checkVisibility(this.value, options.showOn);
            if(match !== false){
                that.show(match, this);
                return true;
            }else{
                match = checkVisibility(this.value, options.hideOn);
                if(match !== false){
                    that.hide(match, this);
                    return true;
                }
            }

            if(match === false){
                that.hide(this.value, this);
                return false;
            }
        }

        function changeHandler(event){
            options.changeCallback(this.value, this);
        }

        function addEventListeners(){
            parentDropdown.addEventListener('change', parentChangeHandler);
            if(options.changeCallback){
                dropdown.addEventListener('change', changeHandler);
            }
        }


        /**
         * Constructor
         *
         * @param usrOptions obj
         *
         * @return void
         *
         */
        function init(){
            parentDropdown = document.querySelector(options.parent);
            dropdown = document.querySelector(options.dropdown);
            addEventListeners();
        }
        init();
    }

    if(window.Educar == undefined){
        window.Educar = {};
    }
    window.Educar.CascadeDropdown = CascadeDropdown;

})(window, document);
