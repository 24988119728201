/**
 * ScreenSize:
 * clase para chequear el tamaño del dispositivo del usuario
 *
 * Ejemplo de uso:
 *
 * La variable Educar.DeviceSize contiene una string con
 * el tamño del dispositivo:
 * Educar.DeviceSize;
 *
 * //string references
 * XS = extra small devices
 * SM = small devices
 * MD = medium devices
 * LG = large devices
 *
 * @author mreboredo
 */

(function(window, document, undefined) {

    'use strict';

    function DeviceInfo(){

        var CONSTANTS = {
            extraSmallDevice: 'XS',
            smallDevice: 'SM',
            mediumDevice: 'MD',
            largeDevice: 'LG',
            testImages: [
                '/img/bandwidth-test/test_20.jpeg',
                '/img/bandwidth-test/test_110.jpeg',
                '/img/bandwidth-test/test_200.jpeg'
            ]
        },
        objTimings = {
            start: null,
            firstByte: null,
            done: null
        },
        req,
        deviceInfo = {
            connection: {
                type: null,
                latency: null,
                throughput: null,
            },
            size: null
        };

        /**
         * Metodo que obtiene el tamaño del dispositivo y
         * setea la string correspondiente para identificarlo
         *
         * @return string
         *
         */
        function deviceSize(){
            var windowWidth = window.innerWidth,
                deviceSize;

            if(windowWidth < 768){
                deviceSize = CONSTANTS.extraSmallDevice;
            }else if(windowWidth >= 768 && windowWidth < 992){
                deviceSize = CONSTANTS.smallDevice;
            }else if (windowWidth >= 992 && windowWidth < 1200) {
                deviceSize = CONSTANTS.mediumDevice;
            }else if(windowWidth >= 1200){
                deviceSize = CONSTANTS.largeDevice;
            }

            return deviceSize;
        }

        /**
         * Progreso de la descarga de la imagen de prueba
         *
         * @param obj progress info
         *
         * @return void
         *
         */
        function progressHandler(data){
            objTimings.firstByte = Date.now();
            req.removeEventListener('progress', progressHandler, false);
        }

        /**
         * Handler de la descarga completa
         *
         * @param obj progress info
         *
         * @return void
         *
         */
        function completeHandler(data){
            objTimings.done = Date.now();
            deviceInfo.connection.latency = objTimings.firstByte - objTimings.start;
            var size = data.target.response.length;
            size = size / 1000;
            deviceInfo.connection.throughput = Math.round(size / (objTimings.done - objTimings.firstByte) * 100) / 100;
        }

        /**
         * Peticion ajax de la imagen de prueba
         *
         * @param int posicion del array de imagenes
         *
         * @return void
         *
         */
        function deviceConn(size){
            req = new XMLHttpRequest()
            req.addEventListener('progress', progressHandler, false);
            req.onload = completeHandler;
            objTimings.start = Date.now();
            req.open("GET", CONSTANTS.testImages[size]);
            req.send();
        }

        deviceInfo.size = deviceSize();

        //se carga en el on load para evitar descargas en paralelo
        Educar.Helpers.onLoad(function(){
            //var conn = deviceConn(0);
            deviceInfo.connection.type = 'connGetType(conn)';
        });

        return deviceInfo;
    }

    if(window.Educar == undefined){
        window.Educar = {};
    }
    window.Educar.DeviceInfo = new DeviceInfo();

})(window, document);
