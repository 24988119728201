/**
 * Clamp:
 * Clase que hace corte de palabrabra.
 *
 * Basado en:
 * https://github.com/laustdeleuran/clamp
 *
 * @TODO:
 * Todavia esta muy verde. Chequear si funcionan bien todos los metodos.
 * Comentar mejor. Ver como hacer el wrapper
 *
 * @author mreboredo
 */

(function(window, document, undefined) {
    // 'use strict';


    function Clamp(element, options) {

        var defaults = {
                lines: 2,
                splitOnChars: ['.', '-', '–', '—', ' '],
                truncationChar: '…'
            },
            options,
            text,
            element;

        /**
         * Obtiene el line-height
         *
         * @param {HTMLElement} elento que contiene el texto a cortar.
         *
         * @returns {Number} lineHeight
         */
        function getLineHeight(element) {
            var text = element.childNodes[0].nodeValue;
            element.childNodes[0].nodeValue = 'A';
            var lineHeight = element.offsetHeight;
            element.childNodes[0].nodeValue = text;
            return parseInt(lineHeight);
        }

        /**
         * Obtiene el height maximo del elemento basado en el numero de lineas
         *
         * @param {HTMLElement} elento que contiene el texto a cortar.
         * @param {Integer} Numero de lineas.
         *
         * @returns {Number} Alto del elemento
         */
        function getMaxHeight(element, lines) {
            var lineHeight = getLineHeight(element);
            if(lines == 0 || lines == undefined || lines == null){
                lines = 1;
            }
            return lineHeight * lines;
        }

        /**
         * Resetea al texto original.
         *
         * @returns {void}
         */
        this.reset = function() {
            if (element.childNodes[0]) {
                element.childNodes[0].nodeValue = text;
            }
        };

        /**
         * Resetea y renderiza de nuevo el corte de palabra (but remember to debounce repeated events).
         *
         * @returns {void}
         */
        this.render = function() {
            // Reset to full text
            this.reset();
            maxHeight = getMaxHeight(element, options.lines);

            if (maxHeight < element.clientHeight) {
                this.truncate();
            }
        };

        /**
         * corta el texto palabra por palabra hasta que entre-
         *
         * @returns {void}
         */
        this.truncate = function(truncateSettings) {

            // It fits!
            if (element.clientHeight <= maxHeight) {
                return false;
            }
            var nodeValue = element.childNodes[0].nodeValue.replace(options.truncationChar, '');
            // First run, create settings
            if (!truncateSettings) { // Use parameter, it's easier to minify
                truncateSettings = {
                    splitOnChars: options.splitOnChars.slice(0), // Local copy of trunc chars
                    chunks: null,
                    lastChunk: null
                };
                truncateSettings.splitOnChar = truncateSettings.splitOnChars[0];
            }

            // Create chunks
            if (!truncateSettings.chunks) {

                // If there are more characters to try, grab the next one
                if (truncateSettings.splitOnChars.length > 0) {
                    truncateSettings.splitChar = truncateSettings.splitOnChars.shift();

                // No characters to chunk by. Go character-by-character
                } else {
                    truncateSettings.splitChar = '';
                }

                truncateSettings.chunks = nodeValue.split(truncateSettings.splitChar);
            }

            // If there are chunks left to remove, remove the last one and see if the nodeValue fits.
            if (truncateSettings.chunks.length > 1) {
                truncateSettings.lastChunk = truncateSettings.chunks.pop();
                element.childNodes[0].nodeValue = truncateSettings.chunks.join(truncateSettings.splitChar) + options.truncationChar;

            // No more chunks can be removed using this character
            } else {
                truncateSettings.chunks = null;
            }

            // It fits!
            if (element.clientHeight <= maxHeight) {
                return false;
            }

            // Recursively truncate
            this.truncate(truncateSettings);
        }

        // -------------- Constructor --------------
        /**
         * Clamps a text node. Greatly inspired by [$clamp](https://github.com/josephschmitt/Clamp.js), but without the `auto` option, as it leads to way too many bugs.
         *
         * @param {HTMLElement} element. Element containing the text node to clamp.
         * @param {Object} options. Options to pass to the clamper.
         *
         * @returns {Object} Instance of Clamp
         */
        this.init = function(){

            options = Educar.Helpers.extend({}, defaults, options);
            element = element;

            if (element.children.length) {
                throw "Clamp.js: Elements with children not supported.";
            }

            if (element.childNodes.length > 1) {
                throw "Clamp.js: Elements with multiple text nodes not supported.";
            }

            text = element.childNodes[0] && element.childNodes[0].nodeValue;

            this.render();

        }

        this.init();
    }
    if(window.Educar == undefined){
        window.Educar = {};
    }

    window.Educar.ClampAction = Clamp;
})(window, document);

