/**
 * Playlist:
 *
 * @author mreboredo
 */

(function(window, document, undefined) {

    'use strict';

    function Playlist(video, callbacks){

        var options = {
                activeClass:    '.active',
                playerSelector: '#js-player',
                indexAttr:      'data-index',
                listItem:       '.js-playlistItem',
                listLink:       '.js-playlistItemLink'
            },
            player = document.querySelector(options.playerSelector),
            itemsList,
            current = (player.getAttribute(options.indexAttr) ? player.getAttribute(options.indexAttr) : 0);

        /**
         *
         *
         * @return void
         */
        function getCurrent(){
            var selector = options.listItem + options.activeClass;
            return document.querySelector(selector).querySelector(options.listLink);
        }

        /**
         *
         *
         * @return void
         */
        function changeSrc(next){
            var current = getCurrent();
            if(current){
                Educar.Helpers.getParents(current, options.listItem)[0].classList.remove(options.activeClass.slice(1));
            }
            Educar.Helpers.getParents(next, options.listItem)[0].classList.add(options.activeClass.slice(1));
            history.pushState(null, null, next.getAttribute('href'));
            if(player.nodeName === 'AUDIO'){
                player.src = next.getAttribute('data-cdn-href');
                document.querySelector('.js-audioTranscription.active').classList.remove('active');
                var ItemId = next.getAttribute('id');
                var transcriptionId = ItemId.replace('audioItem', 'audioTranscription');
                document.querySelector('#' + transcriptionId).classList.add('active');
            }else{
                player.contentWindow.location.replace(next.getAttribute('data-cdn-href'));
            }

            if(callbacks){
                callbacks.changeSrcComplete();
            }
        }

        /**
         *
         *
         * @return void
         */
        function itemClickHandler(e){
            e.preventDefault();
            for(var i = 0, l = itemsList.length; i < l; i++){
                if(itemsList[i] === this){
                    current = i;
                }
            }
            changeSrc(this);
        }

        /**
         *
         *
         * @return void
         */
        this.next = function(){
            current++;
            if(current >= itemsList.length){
                current = 0;
            }
            changeSrc(itemsList[current]);
        };

        /**
         *
         *
         * @return void
         */
        this.previous = function(){
            current = current-1;
            if(current < 0){
                current = itemsList.length - 1;
            }
            changeSrc(itemsList[current]);
        };

        /**
         *
         *
         * @return void
         */
        function popstateHandler(){
            window.location.href = window.location.href;
        }

        /**
         * Constructor
         *
         * @return void
         */
        function init(){
            //@TODO: hacer una clase padre y despues una para audio y otra para video
            window.addEventListener('popstate', popstateHandler);
            itemsList = document.querySelectorAll(options.listLink);

            for (var i = itemsList.length - 1; i >= 0; i--) {
                itemsList[i].addEventListener('click', itemClickHandler, false);
            }
        }
        init();
    }

    if(window.Educar == undefined){
        window.Educar = {};
    }
    window.Educar.Playlist = Playlist;

})(window, document);
