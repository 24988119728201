/**
 * SearchFilters:
 * clase para chequear el tamaño del dispositivo del usuario
 *
 * Ejemplo de uso:
 *
 * La variable Educar.DeviceSize contiene una string con
 * el tamño del dispositivo:
 * Educar.DeviceSize;
 *
 * //string references
 * XS = extra small devices
 * SM = small devices
 * MD = medium devices
 * LG = large devices
 *
 * @author mreboredo
 */

(function(window, document, undefined) {

    'use strict';

    function SearchFilters(){

        var CONSTANTS = {
            extraSmallDevice: 'XS',
        };

        /**
         * remueve o altera filtros
         *
         * @return void
         *
         */
        function removeClickHandler(e){
            e.preventDefault();
            var currentFilters = Educar.Helpers.getUrlParams(),
                parentId = this.getAttribute('data-parent-id'),
                facetName = this.getAttribute('data-facet-name'),
                filters = '';

            if(parentId === null){
                delete currentFilters[facetName];
            }else{
                currentFilters[facetName] = parentId;
            }
            filters = Educar.Helpers.objectToUrlParams(currentFilters);
            window.location.href = filters;
        }

        /**
         * agrega filtros
         *
         * @return void
         *
         */
        function addClickHandler(e){
            e.preventDefault();
            var currentFilters = Educar.Helpers.getUrlParams(),
                facetName = this.getAttribute('data-facet-name'),
                id = this.getAttribute('data-filter-id'),
                filters = '';
            currentFilters[facetName] = id;

            filters = Educar.Helpers.objectToUrlParams(currentFilters);
            window.location.href = filters;
        }

        /**
         * Constructor
         *
         * @return void
         *
         */
        function init(){
            var removeBtnList = document.querySelectorAll('.js-remove-filter');
            var addFilterList = document.querySelectorAll('.js-filter');

            for (var i = addFilterList.length - 1; i >= 0; i--) {
                addFilterList[i].addEventListener('click', addClickHandler);
            }

            for (var i = removeBtnList.length - 1; i >= 0; i--) {
                removeBtnList[i].addEventListener('click', removeClickHandler);
            }
        }
        init();
    }

    if(window.Educar == undefined){
        window.Educar = {};
    }
    window.Educar.SearchFilters = SearchFilters;

})(window, document);
