/**
 * VideoIframe:
 * Clase para recibir los estados del iframe donde se reproduce el video.
 * Dependiendo del evento que se disparo en el iframe ejecuta el metodo
 * correspondiente.
 *
 * @author mreboredo
 */

(function(window, document, undefined) {

    'use strict';

    function VideoIframe(options){

        /**
         * chequea el tipo de vento y ejecuta el metodo correspondiente
         *
         * @return void
         */
        function messageHandler(event){
            if(event.data.origin === 'player'){
                switch(event.data.event){
                    case 'play':
                        options.playCallback();
                        break;
                    case 'stop':
                        options.stopCallback();
                        break;
                    case 'pause':
                        options.pauseCallback();
                        break;
                    case 'complete':
                        options.completeCallback();
                        break;
                }
            }
        }

        /**
         * Constructor
         *
         * @return void
         */
        function init(){
            window.addEventListener('message', messageHandler);
        }
        init();
    }

    if(window.Educar == undefined){
        window.Educar = {};
    }
    window.Educar.VideoIframe = VideoIframe;

})(window, document);
