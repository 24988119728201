/**
 * ResponsiveImages:
 * clase que cambia el src de las imagenes dependiendo del size del dispositivo
 *
 * Ejemplo de uso:
 *
 * @author mreboredo
 */

(function(window, document, undefined) {

    'use strict';

    function ResponsiveImages(){

        var CONSTANTS = {
            xs: 'data-src-xs',
            sm: 'data-src-sm',
            md: 'data-src-md',
            lg: 'data-src-lg'
        },
        options = {
            imageClass: '.js-img-responsive'
        },
        imgsLenght,
        loaded = 0;

        /**
         * Metodo que obtiene el nombre del atributo donde se encuenta el src
         * segun el tamaño del dispositivo
         *
         * @return string / nombre del atributo
         *
         */
        function getSrcName(){
            var attr;
            switch (Educar.DeviceInfo.size) {
                case 'XS':
                    attr = CONSTANTS.xs;
                    break;
                case 'SM':
                    attr = CONSTANTS.xs;
                    break;
                case 'MD':
                    attr = CONSTANTS.md;
                    break;
                case 'LG':
                    attr = CONSTANTS.md;
                    break;
            }
            return attr;
        }

        /**
         * Metodo que remplaza el src de la img por el
         * correspondiente segun el tamaño del dispositivo
         *
         * @param Array / listado de imgs a cambiar el src
         *
         * @return void
         *
         */
        function setSrc(arrImgs){

            var attrSrcName = getSrcName();
            for (var i = 0; i < arrImgs.length; i++) {
                var img = arrImgs[i],
                    src = img.getAttribute(attrSrcName);
                img.setAttribute('src', src);
                img.addEventListener('load',checkImageLoaded);
            }
        }

        /**
         * Metodo que remplaza el src de la img por el
         * correspondiente segun el tamaño del dispositivo
         *
         * @param Array / listado de imgs a cambiar el src
         *
         * @return void
         *
         */
        function checkImageLoaded(evt){
            loaded++;
            var cnt = this.parentNode;
            cnt.classList.remove('loading');
            if(loaded == imgsLenght){
                //trigger del evento
                var changeComplete = new Event('imagesReady');
                var body = document.querySelector('body');
                body.dispatchEvent(changeComplete);
            }
        }


        /**
         * Constructor
         *
         * @param usrOptions obj
         *
         * @return void
         *
         */
        this.init = function(){
            var arrImgs = document.querySelectorAll(options.imageClass);
            imgsLenght = arrImgs.length;
            setSrc(arrImgs);
        }
    }

    if(window.Educar == undefined){
        window.Educar = {};
    }
    window.Educar.ResponsiveImages = ResponsiveImages;

})(window, document);
//<img data-src-xs="http://www.educ.ar/la_url/filtro/2a4dsf2e32ad1c86sfdas.jpg" data-src-sm="http://www.educ.ar/la_url/filtro/2a4dsf2e32ad1c86sfdas.jpg" alt="">
